import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { environment } from './../../../environments/environment';

const API_USERS_URL = `${environment.apiUrl}/`;


@Injectable()
export class HttpRequestsService {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  constructor (
    private http: HttpClient,
    private router: Router
  ) {}

  get(token, endpoint) {
    let httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    });
     
    return this.http.get(API_USERS_URL + endpoint, {
      headers: httpHeaders,
    })
  }

  post(token, endpoint, data) {
    let httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
     
    return this.http.post(API_USERS_URL + endpoint, data, {
      headers: httpHeaders,
    })
  }

  put(token, endpoint, data) {
    let httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.put(API_USERS_URL + endpoint, data, {
      headers: httpHeaders,
    })
  }

  delete(token, endpoint) {
    let httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.delete(API_USERS_URL + endpoint, {
      headers: httpHeaders,
    })
  }

  edit(token, endpoint, data) {
    let httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.put(API_USERS_URL + endpoint, data, {
     headers: httpHeaders,
    })
  }



  getAccessToken() {
    let data = JSON.parse(localStorage.getItem(this.authLocalStorageToken));
    if(data) {
      return data.accessToken;
    } else {
      return null;
    }
  }


}




