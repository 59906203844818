import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient
} from '@angular/common/http';
import { catchError, filter, take, switchMap, retryWhen, concatMap, delay } from "rxjs/operators";
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpRequestsService } from './modules/services/http-request.service';

export const retryCount = 1;
export const retryWaitMilliSeconds = 3000;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  constructor(private injector: Injector, private http: HttpClient) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    console.log(req); // Interception Stage
    const authService = this.injector.get(HttpRequestsService)
    const token = authService.getAccessToken(); // This retrieves a token from local storage
    req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });// This clones HttpRequest and Authorization header with Bearer token added
    req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
 
    return next.handle(req)
        .pipe(
           catchError((error: HttpErrorResponse) => {
                // Catching Error Stage
                if (error && error.status === 401) {
                    console.log("ERROR 401 UNAUTHORIZED") // in case of an error response the error message is displayed
                    let dataToSend = {
                      "clientId": "Poynton Law Front End",
                      "refreshToken": JSON.parse(localStorage.getItem(this.authLocalStorageToken)).refreshToken
                    }
                    this.http.post('https://api.bcas.mfit.uk/api/Login/refreshToken',  dataToSend).subscribe((ret:any) => {
                    localStorage.setItem(this.authLocalStorageToken, JSON.stringify(ret));
                    }), (err: any) => {
                      return err;
                    }
                    return next.handle(req).pipe(
                      retryWhen(error => 
                        error.pipe(
                          concatMap((error, count) => {
                            if (count <= retryCount && error.status == 401) {
                              // console.log(retryCount);
                              return of(error);
                            }
                            return throwError(error);
                          }),
                          delay(retryWaitMilliSeconds)
                        )
                      )
                    )
                }
                const err = error.error.message || error.statusText;
                return throwError(error); // any further errors are returned to frontend                    
           })
        );
  }  
}
